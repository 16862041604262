import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';

@Injectable()
export class TrialNewComponentService {
  constructor(private readonly companyStateService: CompanyStateService) {}

  trialPercentage$: Observable<number> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany: Company) => this.getTrialPercentage(activeCompany)),
  );

  private getTrialPercentage(activeCompany: Company): number {
    if (!activeCompany.subscription?.trialPeriodDays) {
      return 0;
    }
    let trialRemainingDays: number = activeCompany.subscription?.trialRemainingDays ?? 0;
    if (trialRemainingDays < 0) {
      trialRemainingDays = 0;
    }
    let trialPercentage: number = (trialRemainingDays / activeCompany.subscription.trialPeriodDays) * 100;
    if (trialPercentage > 90) {
      trialPercentage = 90;
    }
    return 100 - trialPercentage;
  }
}
