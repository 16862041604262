import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { ReplaceActiveCompanyIdInUrlPipe, RoutingPipe, URL } from '@dougs/core/routing';
import { TrackByPipe } from '@dougs/ds';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { UserMenuComponentService } from '../../../services/user-menu.component.service';
import { UserMenuAdminDropdownComponent } from './user-menu-admin-dropdown/user-menu-admin-dropdown.component';

@Component({
  selector: 'dougs-user-menu-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReplaceActiveCompanyIdInUrlPipe,
    RouterModule,
    TrackByPipe,
    RoutingPipe,
    UserMenuAdminDropdownComponent,
  ],
  templateUrl: './user-menu-dropdown.component.html',
  styleUrls: ['./user-menu-dropdown.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuDropdownComponent {
  @Input() isCompact = false;

  constructor(
    public readonly userMenuComponentService: UserMenuComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  protected readonly URL = URL;
  protected readonly USER_FLAG = USER_FLAG;
}
