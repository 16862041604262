<div class="subscription-line">
  <div class="subscription-line__left">
    <p class="small color-primary-700">Pack choisi</p>
    <p class="small">
      {{ company.subscription?.planDescriptor?.name ?? '' }}
      @if (
        !company.subscription?.isAnnuallyPaid &&
        company.subscription?.planDescriptor?.amount &&
        company.subscription?.quantity &&
        !(userStateService.activeUser$ | async).isEligibleToComptastart
      ) {
        <span> {{ company.subscription.planDescriptor.amount * company.subscription.quantity }} €/mois </span>
      }
      @if (company.subscription?.isAnnuallyPaid) {
        <span>(paiement annuel)</span>
      }
      @if (company.subscription?.suspended) {
        <span>Abonnement résilié</span>
      }
    </p>
  </div>
  @if (planComponentService.shouldShowPlansModal$ | async) {
    <div class="subscription-line__right">
      <dougs-button [fullWidth]="true" size="small" color="secondary" (click)="planComponentService.openPlansModal()">
        Voir les packs
      </dougs-button>
    </div>
  }
</div>
