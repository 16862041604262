import { Injectable } from '@angular/core';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfirmationModalComponent, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { CheckoutOptions } from '@dougs/services/dto';
import { ServicesStateService } from '@dougs/services/shared';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';

@Injectable()
export class BankCardNewComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly servicesStateService: ServicesStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
  ) {}

  showStripeDeleteButton$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    map(
      ([activeCompany, loggedInUser]) =>
        !activeCompany.subscription?.cardBrand &&
        !!activeCompany.subscription?.hasStripeCustomerId &&
        loggedInUser?.flags?.includes(USER_FLAG.CAN_DELETE_STRIPE_CUSTOMER),
    ),
  );

  async openCheckoutModal(company: Company, options: CheckoutOptions): Promise<string | null | undefined> {
    const { CheckoutModalComponent } = await import('../../../modals/checkout-modal/checkout-modal.component');
    return (
      await lastValueFrom(
        this.modalService
          .open<string>(CheckoutModalComponent, {
            data: {
              company,
              options,
            },
          })
          .afterClosed$.pipe(take(1)),
      )
    )?.data;
  }

  async updateCard(company: Company): Promise<void> {
    const clientSecret: string | null | undefined = await this.openCheckoutModal(company, {
      title: 'Mettre à jour ma carte',
      buttonText: 'Valider ma carte',
      loadingText: 'Mise à jour de la carte...',
    });
    if (clientSecret) {
      await this.subscriptionStateService.updateCard(company.id, clientSecret);
      await this.companyStateService.refreshCompany();
    }
  }

  async deleteCard(company: Company): Promise<void> {
    const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
      this.modalService.open<boolean>(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer la carte bancaire',
          body: `Êtes-vous sûr de vouloir supprimer cette carte bancaire ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: 'Oui, supprimer la carte',
        },
      }).afterClosed$,
    );
    if (modalResult?.data) {
      const clientSecret: string | null = await this.servicesStateService.getStripeClientSecret(company);
      if (clientSecret) {
        await this.subscriptionStateService.deleteCard(company.id, clientSecret);
        await this.companyStateService.refreshCompany();
      }
    }
  }

  async deleteCustomerOnStripe(company: Company): Promise<void> {
    const modalResult: OverlayCloseEvent<boolean | null | undefined> = await lastValueFrom(
      this.modalService.open<boolean>(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer le client sur Stripe',
          body: `Êtes-vous sûr de vouloir supprimer ce client sur Stripe ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: 'Oui, supprimer le client sur Stripe',
        },
      }).afterClosed$,
    );
    if (modalResult?.data) {
      const clientSecret: string | null = await this.servicesStateService.getStripeClientSecret(company);
      if (clientSecret) {
        await this.subscriptionStateService.deleteCustomerOnStripe(company.id, clientSecret);
        await this.companyStateService.refreshCompany();
      }
    }
  }
}
