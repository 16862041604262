import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LoaderComponent, TrackByPipe } from '@dougs/ds';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { ControlPanelSearchComponentService } from '../../../services/control-panel/control-panel-search.component.service';
import { TaskComponent } from '../task/task.component';
import { TaskSearchComponent } from '../task-search/task-search.component';

@Component({
  selector: 'dougs-control-panel-task',
  templateUrl: './control-panel-task.component.html',
  styleUrls: ['./control-panel-task.component.scss'],
  animations: [trigger('fadeAnimation', [transition(':leave', animate(200, style({ opacity: 0 })))])],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TaskSearchComponent,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollDirective,
    NgIf,
    LoaderComponent,
    NgFor,
    TaskComponent,
    AsyncPipe,
    TrackByPipe,
  ],
})
export class ControlPanelTaskComponent {
  constructor(
    public readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    public readonly controlPanelSearchComponentService: ControlPanelSearchComponentService,
  ) {}
}
