import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerComponent, InputDatepickerComponent } from '@dougs/ds';
import { SubscriptionPeriodComponentService } from '../../../../services/new/subscription/subscription-period.component.service';

@Component({
  selector: 'dougs-subscription-period-new',
  templateUrl: './subscription-period.component.html',
  styleUrl: '../subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, InputDatepickerComponent, FormsModule, ReactiveFormsModule, DividerComponent, AsyncPipe],
  providers: [SubscriptionPeriodComponentService],
})
export class SubscriptionPeriodNewComponent {
  constructor(public readonly subscriptionPeriodComponentService: SubscriptionPeriodComponentService) {}
}
