import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionNewComponentService } from '../../../services/new/subscription/subscription-new.component.service';

@Component({
  selector: 'dougs-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, ButtonComponent, AsyncPipe, NgIf],
})
export class TrialComponent {
  constructor(
    public readonly subscriptionComponentService: SubscriptionNewComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  trialPercentage$: Observable<number> = this.companyStateService.activeCompany$.pipe(
    map((activeCompany: Company) => this.getTrialPercentage(activeCompany)),
  );

  private getTrialPercentage(activeCompany: Company): number {
    if (!activeCompany.subscription?.trialPeriodDays) {
      return 0;
    }
    let trialRemainingDays: number = activeCompany.subscription?.trialRemainingDays ?? 0;
    if (trialRemainingDays < 0) {
      trialRemainingDays = 0;
    }
    let trialPercentage: number = (trialRemainingDays / activeCompany.subscription.trialPeriodDays) * 100;
    if (trialPercentage > 90) {
      trialPercentage = 90;
    }
    return 100 - trialPercentage;
  }
}
