import { Injectable, Injector, ProviderToken } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { SessionStorageService } from '@dougs/core/storage';
import { Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import {
  TaskActionAccountingBankReconciliationService,
  TaskActionAccountingCategorisationService,
  TaskActionAccountingMonitoringCashPaymentService,
  TaskActionAccountingMonitoringInvestmentCategorizationService,
  TaskActionAccountingMonitoringLargePartnerAccountTransferService,
  TaskActionAccountingQualityControlService,
} from './accounting';
import { TaskActionActivationService } from './activation';
import {
  TaskActionCharteredAccountantClosingVisaService,
  TaskActionCharteredAccountantLabftService,
  TaskActionCharteredAccountantMissionAcceptationService,
  TaskActionCharteredAccountantNonRemunerationCertificateService,
} from './chartered-accountant';
import {
  TaskActionCustomerAcceptSubscriptionPlanPoliciesService,
  TaskActionCustomerAccountingAppoitmentService,
  TaskActionCustomerAccountingInitialAppointmentService,
  TaskActionCustomerAccountingSurveyService,
  TaskActionCustomerAppointmentService,
  TaskActionCustomerAttachsaleDeedRegisteredByTaxAuthoritiesService,
  TaskActionCustomerBalanceSheetsAvailableService,
  TaskActionCustomerBankReconciliationService,
  TaskActionCustomerCapitalDepositService,
  TaskActionCustomerDeclarationNotificationService,
  TaskActionCustomerDownloadMobileAppService,
  TaskActionCustomerDsnAvailableService,
  TaskActionCustomerEsignCreationDocumentsService,
  TaskActionCustomerInitialCreationAppointmentService,
  TaskActionCustomerIrSupportCallService,
  TaskActionCustomerLegalLetterEngagementService,
  TaskActionCustomerMadelinCertificateReminderService,
  TaskActionCustomerNearVatExemptionThresholdService,
  TaskActionCustomerNeedInformationService,
  TaskActionCustomerNegativeAssociateAccountService,
  TaskActionCustomerPaidUpCapitalAdviceService,
  TaskActionCustomerPastDueBillingInvoiceService,
  TaskActionCustomerPaymentStrongCustomerAuthService,
  TaskActionCustomerPayrollByDougsService,
  TaskActionCustomerPayrollSurveyService,
  TaskActionCustomerPurchaseDEBService,
  TaskActionCustomerQuoteNotificationService,
  TaskActionCustomerRegisterOnboardingWebinarService,
  TaskActionCustomerRemoteDeclarationPaymentRejectedService,
  TaskActionCustomerRemoteDeclarationTransmissionFailedNotificationService,
  TaskActionCustomerRemoteTransmissionSetupFinishedService,
  TaskActionCustomerSaleGoodDEBService,
  TaskActionCustomerSaleServicesDESService,
  TaskActionCustomerSalesViaPlatformDocumentUploadService,
  TaskActionCustomerSalesViaPlatformSurveyService,
  TaskActionCustomerSocialKickoffAppointmentService,
  TaskActionCustomerSurveyAppointmentService,
  TaskActionCustomerUnseenFileAttachmentsService,
  TaskActionCustomerUpdateBankInformationsService,
  TaskActionCustomerUpdatePaymentCardService,
  TaskActionCustomerUpdateVatRegimeService,
  TaskActionCustomerUrssafContributionsDocumentRequestService,
} from './customer';
import { TaskActionCcnAnalysisAvailableService } from './customer/task-action-customer-ccn-analysis-available.service';
import { TaskActionCustomerCompanyCompletion } from './customer/task-action-customer-company-completion.service';
import { TaskActionCorrectTaxationAmazonSellerCentralService } from './customer/task-action-customer-correct-taxation-amazon-seller-central.service';
import { TaskActionCustomerCorrectedDsnAvailableService } from './customer/task-action-customer-corrected-dsn-available.service';
import { TaskActionCustomerCreationProjectValidationService } from './customer/task-action-customer-creation-project-validation.service';
import { TaskActionCustomerDownloadKbisService } from './customer/task-action-customer-download-kbis.service';
import { TaskActionCustomerPayslipAvailableService } from './customer/task-action-customer-payslip-available.service';
import { TaskActionCustomerRemoteTransmissionSetupService } from './customer/task-action-customer-remote-transmission-setup.service';
import { TaskActionCustomerSynchronizeBankAccountService } from './customer/task-action-customer-synchronize-bank-account.service';
import { TaskActionCustomerNonRemunerationCertificateService } from './customer/task-action-non-remuneration-certificate.service';
import { TaskActionDefaultService } from './default';
import { TaskActionLegalCreationService } from './legal';
import { TaskActionCheckValidCard, TaskActionCheckValidSubscription, TaskActionUnseenTasks } from './mechanisms';
import { TaskActionSalesEntrySurveyService } from './sales';
import { TaskActionSocialPayslipService } from './social';
import { TaskActionService } from './task-action.service';
import { TaskActionTechSupportService } from './tech';

@Injectable({
  providedIn: 'root',
})
export class TaskActionFacadeService {
  private readonly taskActionsMap: { [code: string]: ProviderToken<TaskActionService> } = {
    'tech:support': TaskActionTechSupportService,
    'customer:declarationNotification': TaskActionCustomerDeclarationNotificationService,
    'customer:pastDueBillingInvoice': TaskActionCustomerPastDueBillingInvoiceService,
    'customer:accountingSurvey': TaskActionCustomerAccountingSurveyService,
    'customer:needInformation': TaskActionCustomerNeedInformationService,
    'customer:unseenFileAttachments': TaskActionCustomerUnseenFileAttachmentsService,
    'customer:negativeAssociateAccount': TaskActionCustomerNegativeAssociateAccountService,
    'customer:bankReconciliation': TaskActionCustomerBankReconciliationService,
    'customer:payrollSurvey': TaskActionCustomerPayrollSurveyService,
    'customer:payrollByDougs': TaskActionCustomerPayrollByDougsService,
    'customer:acceptSubscriptionPlanPolicies': TaskActionCustomerAcceptSubscriptionPlanPoliciesService,
    'customer:remoteDeclarationPaymentRejected': TaskActionCustomerRemoteDeclarationPaymentRejectedService,
    'customer:urssafContributionsDocumentRequest': TaskActionCustomerUrssafContributionsDocumentRequestService,
    'customer:quoteNotification': TaskActionCustomerQuoteNotificationService,
    'customer:paymentStrongCustomerAuthentication': TaskActionCustomerPaymentStrongCustomerAuthService,
    'customer:socialKickoffAppointment': TaskActionCustomerSocialKickoffAppointmentService,
    'customer:accountingInitialAppointment': TaskActionCustomerAccountingInitialAppointmentService,
    'customer:madelinCertificatesReminder': TaskActionCustomerMadelinCertificateReminderService,
    'customer:balanceSheetsAvailable': TaskActionCustomerBalanceSheetsAvailableService,
    'customer:correctedDsnAvailable': TaskActionCustomerCorrectedDsnAvailableService,
    'customer:dsnAvailable': TaskActionCustomerDsnAvailableService,
    'customer:payslipAvailable': TaskActionCustomerPayslipAvailableService,
    'customer:initialCreationAppointment': TaskActionCustomerInitialCreationAppointmentService,
    'customer:registerOnboardingWebinar': TaskActionCustomerRegisterOnboardingWebinarService,
    'customer:capitalDeposit': TaskActionCustomerCapitalDepositService,
    'customer:irSupportCall': TaskActionCustomerIrSupportCallService,
    'customer:creationProjectValidation': TaskActionCustomerCreationProjectValidationService,
    'customer:surveyAppointment': TaskActionCustomerSurveyAppointmentService,
    'legal:48hCreation': TaskActionLegalCreationService,
    'legal:new48hCreation': TaskActionLegalCreationService,
    'legal:standardCreation': TaskActionLegalCreationService,
    'legal:newStandardCreation': TaskActionLegalCreationService,
    'legal:creationSciAutomatic': TaskActionLegalCreationService,
    'legal:creationSASAutomatic': TaskActionLegalCreationService,
    'legal:creationEURLAutomatic': TaskActionLegalCreationService,
    'customer:esignCreationDocuments': TaskActionCustomerEsignCreationDocumentsService,
    'customer:updatePaymentCard': TaskActionCustomerUpdatePaymentCardService,
    'customer:paidUpCapitalAdvice': TaskActionCustomerPaidUpCapitalAdviceService,
    'customer:purchaseDEB': TaskActionCustomerPurchaseDEBService,
    'customer:saleServiceDES': TaskActionCustomerSaleServicesDESService,
    'customer:saleGoodDEB': TaskActionCustomerSaleGoodDEBService,
    'customer:salesViaPlatformSurvey': TaskActionCustomerSalesViaPlatformSurveyService,
    'customer:salesViaPlatformDocumentUpload': TaskActionCustomerSalesViaPlatformDocumentUploadService,
    'customer:updateVatRegimeAfterOutsideUePurchase': TaskActionCustomerUpdateVatRegimeService,
    'customer:legalLetterOfEngagement': TaskActionCustomerLegalLetterEngagementService,
    'customer:updateBankInformations': TaskActionCustomerUpdateBankInformationsService,
    'social:payslip': TaskActionSocialPayslipService,
    'sales:entrySurvey': TaskActionSalesEntrySurveyService,
    activation: TaskActionActivationService,
    'accounting:monitoring:investmentsCategorization': TaskActionAccountingMonitoringInvestmentCategorizationService,
    'accounting:monitoring:cashPayment': TaskActionAccountingMonitoringCashPaymentService,
    'accounting:monitoring:largePartnerAccountTransfer':
      TaskActionAccountingMonitoringLargePartnerAccountTransferService,
    'accounting:clientOperationCategorizationSupportAndFormation': TaskActionAccountingCategorisationService,
    'accounting:clientOperationCategorizationEce': TaskActionAccountingCategorisationService,
    'accounting:bankReconciliation': TaskActionAccountingBankReconciliationService,
    'accounting:guidedBankReconciliation': TaskActionAccountingBankReconciliationService,
    'accounting:qualityControlReview': TaskActionAccountingQualityControlService,
    'social:qualityControlReview': TaskActionAccountingQualityControlService,
    'care:qualityControlReview': TaskActionAccountingQualityControlService,
    'charteredAccountant:nonRemunerationCertificate.signature':
      TaskActionCharteredAccountantNonRemunerationCertificateService,
    'charteredAccountant:closingVisa': TaskActionCharteredAccountantClosingVisaService,
    'charteredAccountant:labft': TaskActionCharteredAccountantLabftService,
    'charteredAccountant:missionAcceptation': TaskActionCharteredAccountantMissionAcceptationService,
    unseenTasks: TaskActionUnseenTasks,
    checkValidSubscription: TaskActionCheckValidSubscription,
    checkValidCard: TaskActionCheckValidCard,
    'customer:companyCompletion': TaskActionCustomerCompanyCompletion,
    'customer:failedRemoteDeclarationNotification':
      TaskActionCustomerRemoteDeclarationTransmissionFailedNotificationService,
    'customer:appointment': TaskActionCustomerAppointmentService,
    'customer:accountingAppointment': TaskActionCustomerAccountingAppoitmentService,
    'customer:remoteTransmissionSetupFinished': TaskActionCustomerRemoteTransmissionSetupFinishedService,
    'customer:remoteTransmissionSetup': TaskActionCustomerRemoteTransmissionSetupService,
    'customer:attachsaleDeedRegisteredByTaxAuthorities':
      TaskActionCustomerAttachsaleDeedRegisteredByTaxAuthoritiesService,
    'customer:ccnAnalysisAvailable': TaskActionCcnAnalysisAvailableService,
    'customer:vatExemptionNearThreshold': TaskActionCustomerNearVatExemptionThresholdService,
    'customer:correctTaxationAmazonSellerCentral': TaskActionCorrectTaxationAmazonSellerCentralService,
    'customer:accounting:downloadKbis': TaskActionCustomerDownloadKbisService,
    'customer:downloadMobileApp': TaskActionCustomerDownloadMobileAppService,
    'customer:document:accounting:nonRemunerationCertificate': TaskActionCustomerNonRemunerationCertificateService,
    'customer:synchronizeBankAccount': TaskActionCustomerSynchronizeBankAccountService,
  };

  constructor(
    private readonly injector: Injector,
    private readonly userStateService: UserStateService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public executeTasksAction(...tasks: Task[]): Observable<unknown> {
    const firstTask: Task = tasks[0];
    const remainingTasks: Task[] = tasks.length > 1 ? tasks.slice(1) : [];
    const tasksExecutionObservable: Observable<unknown> = remainingTasks.reduce(
      (accObs, currentTask) =>
        accObs.pipe(concatMap(() => from(this.getTaskActionAndAutomaticallyExecuteIfNecessary(currentTask)))),
      from(this.getTaskActionAndAutomaticallyExecuteIfNecessary(firstTask)),
    );
    return tasksExecutionObservable;
  }

  getTaskActionAndAutomaticallyExecuteIfNecessary(task: Task): Promise<unknown> {
    const taskAction: TaskActionService = this.getTaskAction(task);
    const loggedInUser: User = this.userStateService.loggedInUser;
    const hasAutomaticallyExecutedActionableTasks: boolean = this.sessionStorageService.retrieve(
      'hasAutomaticallyExecutedActionableTasks',
    );
    if (taskAction.shouldExecuteAutomatically(loggedInUser, hasAutomaticallyExecutedActionableTasks)) {
      return taskAction.automaticallyExecute(task);
    }
    return Promise.resolve();
  }

  public getTaskActionLabel(task: Task): string {
    return this.getTaskAction(task).getLabel(task);
  }

  public getTaskActionIcon(task: Task): string {
    return this.getTaskAction(task).getIcon(task);
  }

  public getTaskActionTag(task: Task): string {
    return this.getTaskAction(task).getTag();
  }

  public getTaskActionTagColor(task: Task): Pill {
    return this.getTaskAction(task).getTagColor();
  }

  public getTaskActionTagIcon(task: Task): string {
    return this.getTaskAction(task).getTagIcon();
  }

  public getTaskActionAvailable(task: Task): boolean {
    return this.getTaskAction(task).getIsAvailable(task);
  }

  public taskCodeHasAnExecution(taskCode: string): boolean {
    return !!taskCode && !!this.taskActionsMap[taskCode];
  }

  public getTaskActionCloseNotificationOnExecute(task: Task): boolean {
    return this.getTaskAction(task).getCloseNotificationOnExecute(task);
  }

  public async executeTaskAction(task: Task): Promise<void> {
    await this.getTaskAction(task).execute(task);
  }

  private getTaskAction(task: Task): TaskActionService {
    const correspondingTaskAction: TaskActionService = this.taskActionsMap[task.code]
      ? this.injector.get(this.taskActionsMap[task.code])
      : this.injector.get(TaskActionDefaultService);
    return correspondingTaskAction;
  }
}
