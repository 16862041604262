import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent, DividerComponent, DougsDatePipe } from '@dougs/ds';
import { PlanComponentService } from '../../../../services/new/subscription/plan.component.service';

@Component({
  selector: 'dougs-next-billing-new',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DividerComponent, DougsDatePipe],
  providers: [PlanComponentService],
  templateUrl: './next-billing.component.html',
  styleUrl: '../subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextBillingNewComponent {
  @Input({ required: true }) nextBillingAt!: Date | string;
}
