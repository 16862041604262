export * from './task-action-customer-accept-subscription-plan-policies.service';
export * from './task-action-customer-accounting-initial-appointment.service';
export * from './task-action-customer-accounting-survey.service';
export * from './task-action-customer-bank-reconciliation.service';
export * from './task-action-customer-declaration-notification.service';
export * from './task-action-customer-need-information.service';
export * from './task-action-customer-negative-associate-account.service';
export * from './task-action-customer-past-due-billing-invoice.service';
export * from './task-action-customer-payment-strong-customer-auth.service';
export * from './task-action-customer-payroll-by-dougs.service';
export * from './task-action-customer-payroll-survey.service';
export * from './task-action-customer-quote-notification.service';
export * from './task-action-customer-remote-declaration-payment-rejected.service';
export * from './task-action-customer-social-kickoff-appointment.service';
export * from './task-action-customer-unseen-file-attachments.service';
export * from './task-action-customer-urssaf-contributions-document-request.service';
export * from './task-action-customer-accounting-survey.service';
export * from './task-action-customer-balance-sheets-available.service';
export * from './task-action-customer-bank-reconciliation.service';
export * from './task-action-customer-capital-deposit.service';
export * from './task-action-customer-esign-creation-documents.service';
export * from './task-action-customer-initial-creation-appointment.service';
export * from './task-action-customer-madelin-certificate-reminder.service';
export * from './task-action-customer-paid-up-capital-advice.service';
export * from './task-action-customer-payment-strong-customer-auth.service';
export * from './task-action-customer-purchase-DEB.service';
export * from './task-action-customer-register-onboarding-webinar.service';
export * from './task-action-customer-sale-good-DEB.service';
export * from './task-action-customer-sale-services-DES.service';
export * from './task-action-customer-sales-via-platform-document-upload.service';
export * from './task-action-customer-sales-via-platform-survey.service';
export * from './task-action-customer-update-payment-card.service';
export * from './task-action-customer-update-vat-regime.service';
export * from './task-action-customer-legal-letter-engagement.service';
export * from './task-action-customer-update-bank-informations.service';
export * from './task-action-customer-ir-support-call.service';
export * from './task-action-customer-remote-declaration-transmission-failed-notification.service';
export * from './task-action-customer-accounting-appoitment.service';
export * from './task-action-customer-remote-transmission-setup-finished';
export * from './task-action-customer-dsn-available.service';
export * from './task-action-customer-appointment.service';
export * from './task-action-customer-attach-business-assets-sale-deed-registered-by-tax-authorities.service';
export * from './task-action-customer-survey-appointment.service';
export * from './task-action-customer-near-vat-exemption-threshold.service';
export * from './task-action-customer-download-mobile-app.service';
