import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AvatarComponent,
  ButtonComponent,
  ControlFormFieldDirective,
  CopyToClipboardDirective,
  DropdownComponent,
  FooterStuckDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { ReferrerStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { FormReferrerService } from '../../../services/new/referrer/form-referrer.service';
import { ReferrerComponentService } from '../../../services/new/referrer/referrer.component.service';
import { ReferrerUserDropdownComponent } from '../../referrer/referrer-user-dropdown/referrer-user-dropdown.component';
import { ReferrerPartnershipComponent } from './referrer-partnership/referrer-partnership.component';

@Component({
  selector: 'dougs-referrer-new',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    CopyToClipboardDirective,
    AvatarComponent,
    DropdownComponent,
    FormFieldComponent,
    ReferrerUserDropdownComponent,
    SuffixFormFieldDirective,
    ReactiveFormsModule,
    FooterStuckDirective,
    ReferrerPartnershipComponent,
  ],
  providers: [ReferrerComponentService, FormReferrerService],
  templateUrl: './referrer.component.html',
  styleUrl: './referrer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferrerNewComponent {
  @ViewChild('dropdownUsers') set dropdownUsers(dropdownComponent: DropdownComponent) {
    this.referrerComponentService.setDropdownUsers(dropdownComponent);
  }

  constructor(
    public readonly userStateService: UserStateService,
    public readonly referrerStateService: ReferrerStateService,
    public readonly referrerComponentService: ReferrerComponentService,
    public readonly formReferrerService: FormReferrerService,
  ) {}
}
