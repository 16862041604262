import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarMenuEventsService {
  private readonly sidebarMenuToggled: Subject<void> = new Subject<void>();
  sidebarMenuToggled$: Observable<void> = this.sidebarMenuToggled.asObservable();

  propagateToggleSidebarMenu(): void {
    this.sidebarMenuToggled.next();
  }
}
