import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanyStateService } from '@dougs/company/shared';
import {
  CheckboxComponent,
  ControlFormFieldDirective,
  DividerComponent,
  FormFieldComponent,
  LabelFormFieldDirective,
} from '@dougs/ds';
import { FormReferrerService } from '../../../../services/new/referrer/form-referrer.service';

@Component({
  selector: 'dougs-referrer-partnership',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DividerComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    CheckboxComponent,
  ],
  templateUrl: './referrer-partnership.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferrerPartnershipComponent {
  constructor(
    public readonly formReferrerService: FormReferrerService,
    public readonly companyStateService: CompanyStateService,
  ) {}
}
