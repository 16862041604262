import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionNewComponentService } from './subscription-new.component.service';

@Injectable()
export class PlanComponentService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly subscriptionComponentService: SubscriptionNewComponentService,
    private readonly metricsService: MetricsService,
  ) {}

  shouldShowPlansModal$: Observable<boolean> = combineLatest([
    this.userStateService.activeUser$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([activeUser, services]) =>
        !activeUser.isEligibleToComptastart ||
        (activeUser.isEligibleToComptastart && !!services?.accounting.isTrialing),
    ),
  );

  async openPlansModal(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Subscription Accounting Settings See Plans CTA Clicked');
    await this.subscriptionComponentService.openPlansModal();
  }
}
