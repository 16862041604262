@if (companyStateService.activeCompany$ | async; as activeCompany) {
  <div class="form-grid" [formGroup]="formReferrerService.metadataFormGroup">
    <dougs-divider></dougs-divider>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Durée de période d'essai</label>
      <input
        id="partnershipAccountingTrialPeriodDays"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        formControlName="partnershipAccountingTrialPeriodDays"
      />
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Nombre de mois offerts</label>
      <input
        id="partnershipAccountingFreeMonths"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        formControlName="partnershipAccountingFreeMonths"
      />
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Nombre de mois payés auprès du partenaire</label>
      <input
        id="partnershipAlreadyPaidMonthCount"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        formControlName="partnershipAlreadyPaidMonthCount"
      />
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Activer le reliquat d'honoraire</label>
      <dougs-checkbox
        class="mt-4"
        appearance="toggle"
        [showYesNo]="true"
        formControlName="partnershipAccountingHasRemainder"
      ></dougs-checkbox>
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Remise sur création d'entreprise</label>
      <input
        id="partnershipDiscountOnCompanyCreation"
        type="number"
        placeholder="0"
        dougsFormFieldControl
        formControlName="partnershipDiscountOnCompanyCreation"
      />
    </dougs-form-field>
    <dougs-form-field [noMargin]="true">
      <label [isAdmin]="true" dougsFormFieldLabel>Moyen de paiement de l'abonnement</label>
      <input
        id="partnershipSubscriptionPaymentMethod"
        type="text"
        placeholder="card, partner"
        dougsFormFieldControl
        formControlName="partnershipSubscriptionPaymentMethod"
      />
    </dougs-form-field>
  </div>
}
