import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { DragNDropDirective, FileInputComponent, FilePillComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { AttachmentAutoValidationService } from '../../../../services/control-panel/task/attachment-auto-validation.service';
import { TaskFilesComponentService } from '../../../../services/control-panel/task/task-files.component.service';

@Component({
  selector: 'dougs-task-files',
  templateUrl: './task-files.component.html',
  styleUrls: ['./task-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, DragNDropDirective, FileInputComponent, FilePillComponent, TrackByPipe, TooltipDirective],
  providers: [TaskFilesComponentService, AttachmentAutoValidationService],
})
export class TaskFilesComponent {
  private _taskTemplate!: TaskTemplate | null;
  private _task!: Task;

  attachmentByType: { [key: string]: Attachment[] } = {};

  @Input()
  set taskTemplate(taskTemplate: TaskTemplate | null) {
    this._taskTemplate = taskTemplate;
    this.attachmentByType = this.taskFilesComponentService.getAttachmentByType(this.task, taskTemplate);
  }

  get taskTemplate(): TaskTemplate | null {
    return this._taskTemplate;
  }

  @Input()
  set task(task: Task) {
    this._task = task;
    this.attachmentByType = this.taskFilesComponentService.getAttachmentByType(task, this.taskTemplate);
  }

  get task(): Task {
    return this._task;
  }

  constructor(public readonly taskFilesComponentService: TaskFilesComponentService) {}
}
