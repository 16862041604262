import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ButtonComponent } from '@dougs/ds';
import { ProductsService } from '@dougs/revenue/products/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionComponentService } from '../../../services/subscription.component.service';

@Component({
  selector: 'dougs-plan',
  templateUrl: './plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ButtonComponent, AsyncPipe],
})
export class PlanComponent {
  constructor(
    public readonly subscriptionComponentService: SubscriptionComponentService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public productsService: ProductsService,
    public metricsService: MetricsService,
  ) {}

  shouldShowPlansModal$: Observable<boolean> = combineLatest([
    this.userStateService.activeUser$,
    this.companyServicesStateService.services$,
  ]).pipe(
    map(
      ([activeUser, services]) =>
        !activeUser.isEligibleToComptastart ||
        (activeUser.isEligibleToComptastart && !!services?.accounting.isTrialing),
    ),
  );

  async openPlansModal(): Promise<void> {
    this.metricsService.pushMixpanelEvent('Subscription Accounting Settings See Plans CTA Clicked');
    await this.subscriptionComponentService.openPlansModal();
  }
}
