import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, map, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { SubscriptionStateService } from '@dougs/subscription/shared';

@Injectable()
export class SubscriptionPeriodComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
  ) {}

  dateFormControl: FormControl = new FormControl('', { updateOn: 'blur' });

  dateFormControlChanges$: Observable<void> = this.dateFormControl.valueChanges.pipe(
    filter(() => !this.dateFormControl.invalid),
    concatMap((value) =>
      this.subscriptionStateService.updateSubscriptionPeriodsEndDate(this.companyStateService.activeCompany, value),
    ),
    concatMap(() => this.companyStateService.refreshCompany()),
  );

  populateDateFormControl$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    map((activeCompany) => this.setFormControlDate(activeCompany.subscription?.periodEndsAt)),
  );

  private setFormControlDate(periodEndsAt?: Date | string): void {
    if (periodEndsAt) {
      this.dateFormControl.setValue(periodEndsAt, { emitEvent: false });
    }
  }
}
