import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { ButtonComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { PlanComponentService } from '../../../../services/new/subscription/plan.component.service';

@Component({
  selector: 'dougs-plan-new',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  providers: [PlanComponentService],
  templateUrl: './plan.component.html',
  styleUrl: '../subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanNewComponent {
  constructor(
    public readonly userStateService: UserStateService,
    public readonly planComponentService: PlanComponentService,
  ) {}

  @Input({ required: true }) company!: Company;
}
