import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, DividerComponent, DougsDatePipe } from '@dougs/ds';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { BankCardNewComponentService } from '../../../../services/new/subscription/bank-card-new.component.service';

@Component({
  selector: 'dougs-bank-card-new',
  templateUrl: './bank-card.component.html',
  styleUrl: '../subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [BankCardNewComponentService],
  imports: [NgIf, ButtonComponent, DividerComponent, AsyncPipe, DougsDatePipe],
})
export class BankCardNewComponent {
  constructor(
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly bankCardNewComponentService: BankCardNewComponentService,
  ) {}

  @Input({ required: true }) company!: Company;
  protected readonly USER_FLAG = USER_FLAG;
}
