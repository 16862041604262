import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { SubscriptionStateService } from '@dougs/subscription/shared';

@Injectable()
export class TermOfUseComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly window: Window,
  ) {}

  private readonly openedSignedTermsList: WritableSignal<boolean> = signal(false);
  openedSignedTermsList$: Signal<boolean> = this.openedSignedTermsList.asReadonly();

  refreshSignedTerms$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.subscriptionStateService.refreshSubscriptionSignedTerms(activeCompany.id)),
  );

  async regenerateTermOfUse(company: Company): Promise<void> {
    await this.subscriptionStateService.regenerateTermOfUse(company?.id);
  }

  toggleOpenedSignedTermsList(): void {
    this.openedSignedTermsList.update((opened) => !opened);
  }

  downloadSignedTerm(signedTerm: Attachment): void {
    this.window.open(signedTerm.file.url, '_blank');
  }
}
