@if (referrerComponentService.refreshReferralStats$ | async) {}
@if (referrerComponentService.populateForm$ | async) {}
<form [formGroup]="formReferrerService.formGroup" (ngSubmit)="formReferrerService.onSubmit()" class="form-grid">
  @if (userStateService.activeUser$ | async; as activeUser) {
    <div class="referrer-link-container py-16 px-24">
      <div class="referrer-image-container">
        <img class="referrer-image" src="images/illustrations/referrer.png" />
      </div>
      <div class="referrer-link-text">
        <h5 class="title mb-8">Parrainez vos proches</h5>
        <div class="subtitle mb-8">
          {{
            !activeUser.isEligibleToComptastart
              ? "Partagez votre lien de parrainage, nous offrons un mois de comptabilité à vos filleuls et vous bénéficiez d'un \nmois de comptabilité offert pour chaque inscription activée."
              : 'Partagez votre lien de parrainage, nous offrons un mois de comptabilité à vos filleuls.'
          }}
        </div>
        <div class="referral-link mb-8">
          <div class="referral-link__input">
            <label dougsFormFieldLabel class="mb-4">Lien de parrainage</label>
            <input
              dougsFormFieldControl
              type="text"
              disabled
              [value]="'https://www.dougs.fr?r=' + activeUser.referralHash"
            />
          </div>
          <dougs-button
            color="admin"
            [dougsCopyToClipboard]="'https://www.dougs.fr?r=' + activeUser.referralHash"
            (click)="referrerComponentService.handleCopyLinkButtonClick()"
          >
            Copier mon lien
          </dougs-button>
        </div>
      </div>
    </div>
    @if ((userStateService.loggedInUser$ | async)?.isAccountantOrAdmin) {
      <div class="selected-referrer" (click)="referrerComponentService.openUsersDropdown()" #referer>
        <dougs-form-field [noMargin]="true">
          <label dougsFormFieldLabel [isAdmin]="true">Parrain</label>
          <div dougsFormFieldControl class="p-8">
            {{ activeUser.referrer?.profile?.fullName ?? 'Pas de parrain' }}
          </div>
          @if (!activeUser.referrer) {
            <i dougsFormFieldSuffix class="fal fa-user"></i>
          }
          @if (activeUser.referrer) {
            <dougs-avatar
              dougsFormFieldSuffix
              size="small"
              class="avatar"
              [image]="activeUser.referrer.profile?.avatarUrl || activeUser.referrer.profile?.alternateAvatarUrl"
            ></dougs-avatar>
          }
        </dougs-form-field>
      </div>
      <dougs-dropdown
        class="select-dropdown"
        #dropdownUsers
        [reference]="referer"
        [widthToRefElement]="true"
        (closed)="referrerComponentService.onDropdownClose()"
      >
        <dougs-referrer-user-dropdown
          [isOpened]="referrerComponentService.dropdownOpened$()"
          [shouldShowMe]="true"
          (selectUser)="referrerComponentService.updateSelectedReferrer($event)"
        ></dougs-referrer-user-dropdown>
      </dougs-dropdown>
    }
    @if (referrerStateService.referralStats$ | async; as referralStats) {
      <div>
        <h6 class="stats-title">Filleuls</h6>
        <div class="stats-text">
          @if (referralStats?.totalRefereeCount > 0) {
            <span>
              Vous avez {{ referralStats?.totalRefereeCount ?? 0 }} filleuls dont
              {{ referralStats?.trialRefereeCount ?? 0 }} en période d'essai.
              @if (!activeUser.isEligibleToComptastart) {
                <span>
                  À ce jour, vous avez donc bénéficié de {{ referralStats?.activeRefereeCount ?? 0 }} mois offerts.
                </span>
              }
            </span>
          }
          @if (!referralStats?.totalRefereeCount) {
            <span>Vous n'avez aucun filleul pour le moment.</span>
          }
        </div>
      </div>
    }
    @if (referrerComponentService.showPartnership$ | async) {
      <dougs-referrer-partnership />
    }
  }
  @if (formReferrerService.formGroup.dirty) {
    <div footerStuck class="form-grid__buttons">
      <dougs-button color="secondary" (click)="formReferrerService.resetForm()">Annuler</dougs-button>
      <dougs-button type="submit">Enregistrer</dougs-button>
    </div>
  }
</form>
