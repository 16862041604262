import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { SubscriptionNewComponentService } from '../../../../services/new/subscription/subscription-new.component.service';
import { TrialNewComponentService } from '../../../../services/new/subscription/trial-new.component.service';

@Component({
  selector: 'dougs-trial-new',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, ButtonComponent, AsyncPipe, NgIf],
  providers: [TrialNewComponentService],
})
export class TrialNewComponent {
  constructor(
    public readonly subscriptionComponentService: SubscriptionNewComponentService,
    public readonly trialNewComponentService: TrialNewComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
  ) {}

  @Input({ required: true }) company!: Company;
}
