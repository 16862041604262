import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';
import { BillingInvoiceItem } from '@dougs/subscription/dto';
import { CreateTaskDto, Task, TaskConfiguratorContext, TaskConfiguratorContextVariableAnyType } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { OpenTaskModalService } from './modals/open-task-modal.service';

type TaskCreateModalConfigurator = CreateTaskDto & {
  taskConfiguratorVariables: TaskConfiguratorContextVariableAnyType[];
};

@Injectable()
export class CreateTaskComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly openTaskModalService: OpenTaskModalService,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {}

  async addTask(initData?: CreateTaskDto): Promise<void> {
    const { CreateTaskModalComponent } = await import('../modals/create-task-modal/create-task-modal.component');
    const taskModalResponse: Task | TaskCreateModalConfigurator | null | undefined = (
      await lastValueFrom(
        this.modalService.open<Task | TaskCreateModalConfigurator>(CreateTaskModalComponent, { data: initData })
          .afterClosed$,
      )
    ).data;

    if (!taskModalResponse) {
      return;
    }

    if (this.isTask(taskModalResponse)) {
      return this.finalizeTaskCreation(taskModalResponse);
    }
    return this.openConfiguratorModal(taskModalResponse);
  }

  private async finalizeTaskCreation(taskCreated: Task): Promise<void> {
    if (taskCreated.billableServiceIds) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { AddBillingInvoiceItemModalComponent } = await import('@dougs/subscription/ui');
      const billableServiceItems: BillingInvoiceItem[] | null | undefined = (
        await lastValueFrom(
          this.modalService.open<BillingInvoiceItem[]>(AddBillingInvoiceItemModalComponent, {
            data: {
              billableServiceIds: taskCreated.billableServiceIds,
              addBillingInvoiceItem: true,
            },
          }).afterClosed$,
        )
      ).data;

      if (billableServiceItems?.length) {
        await this.controlPanelTasksStateService.addService(taskCreated, billableServiceItems);
      }
    }

    if (taskCreated.hasForm) {
      await this.openTaskModalService.openTaskModal(taskCreated.id);
    }
  }

  private async openConfiguratorModal(taskContext: TaskCreateModalConfigurator): Promise<void> {
    if (!taskContext.taskConfiguratorVariables) {
      return;
    }

    const { TaskConfiguratorModalComponent } = await import(
      '../modals/task-configurator-modal/task-configurator-modal.component'
    );

    const configuratorResponse: Task | TaskConfiguratorContext | null | undefined = (
      await toPromise(
        this.modalService.open<
          TaskConfiguratorContext | Task,
          {
            task: CreateTaskDto;
            context: TaskConfiguratorContextVariableAnyType[];
            readonly: boolean;
          }
        >(TaskConfiguratorModalComponent, {
          data: { task: taskContext, context: taskContext.taskConfiguratorVariables, readonly: false },
        }).afterClosed$,
      )
    ).data;

    if (!configuratorResponse) {
      return this.addTask(taskContext);
    }

    return this.finalizeTaskCreation(configuratorResponse as Task);
  }

  private isTask(task: Task | TaskCreateModalConfigurator): task is Task {
    return (<Task>task)?.id !== undefined;
  }
}
