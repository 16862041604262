import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent, DividerComponent, DougsDatePipe, TrackByPipe } from '@dougs/ds';
import { SubscriptionStateService } from '@dougs/subscription/shared';
import { UserStateService } from '@dougs/user/shared';
import { TermOfUseComponentService } from '../../../../services/new/subscription/term-of-use.component.service';

@Component({
  selector: 'dougs-term-of-use-new',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss', '../subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, ButtonComponent, DividerComponent, NgFor, AsyncPipe, TrackByPipe, DougsDatePipe],
  providers: [TermOfUseComponentService],
})
export class TermOfUseNewComponent {
  constructor(
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly subscriptionStateService: SubscriptionStateService,
    public readonly termOfUseComponentService: TermOfUseComponentService,
  ) {}

  @Input({ required: true }) company!: Company;
}
