import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { combineLatest, concatMap, map, Observable } from 'rxjs';
import { COMPANY_FLAG, CompanyStateService } from '@dougs/company/shared';
import { MetricsService, MIXPANEL_EVENT } from '@dougs/core/metrics';
import { mergeObjects } from '@dougs/core/utils';
import { DropdownComponent } from '@dougs/ds';
import { ReferrerStateService } from '@dougs/subscription/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { FormReferrerService } from './form-referrer.service';

@Injectable()
export class ReferrerComponentService {
  refreshReferralStats$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    concatMap((activeUser) => this.referrerStateService.refreshReferralStats(activeUser.id)),
  );
  private dropdownComponent?: DropdownComponent;

  private readonly dropdownOpened: WritableSignal<boolean> = signal(false);
  dropdownOpened$: Signal<boolean> = this.dropdownOpened.asReadonly();

  populateForm$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    map((company) => this.formReferrerService.populateForm(company)),
  );

  showPartnership$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(map(([user, company]) => !!user?.isAdmin && !!company.flags?.includes(COMPANY_FLAG.ENABLE_PARTNERSHIP)));

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly referrerStateService: ReferrerStateService,
    private readonly formReferrerService: FormReferrerService,
    private readonly metricsService: MetricsService,
  ) {}

  handleCopyLinkButtonClick(): void {
    this.metricsService.pushMixpanelEvent(MIXPANEL_EVENT.SettingsSponsorshipCopyLinkButtonClicked);
  }

  openUsersDropdown(): void {
    if (this.dropdownComponent) {
      this.dropdownComponent.show();
      this.dropdownOpened.set(true);
    }
  }

  hideUsersDropdown(): void {
    if (this.dropdownComponent) {
      this.dropdownComponent.hide();
      this.onDropdownClose();
    }
  }

  onDropdownClose(): void {
    this.dropdownOpened.set(false);
  }

  async updateSelectedReferrer(selectedUser: User | null): Promise<void> {
    this.hideUsersDropdown();
    const updatedUser: User = mergeObjects(this.userStateService.activeUser, {
      referrerHash: selectedUser?.referralHash ?? null,
      referrer: selectedUser,
    });
    await this.userStateService.updateUserWithoutRefreshState(updatedUser);
    await this.userStateService.refreshActiveUser(this.userStateService.activeUser);
  }

  setDropdownUsers(dropdownComponent: DropdownComponent): void {
    this.dropdownComponent = dropdownComponent;
  }
}
