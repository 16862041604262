import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { ModalService } from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { EmailPreviewModalComponent } from '../../../modals/email-preview-modal/email-preview-modal.component';
import { TaskService } from '../../task.service';

@Injectable()
export class TaskItemComponentService {
  private readonly isLoading = signal(false);
  isLoading$ = computed(() => this.isLoading());

  private readonly task: WritableSignal<Task | null> = signal(null);
  task$: Signal<Task | null> = this.task.asReadonly();

  private readonly taskTemplate: WritableSignal<TaskTemplate | null> = signal(null);
  taskTemplate$: Signal<TaskTemplate | null> = this.taskTemplate.asReadonly();

  canPreviewMail$: Signal<boolean> = computed(() => this.canDisplayEmailPreview(this.task$(), this.taskTemplate$()));

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly taskService: TaskService,
    private readonly modalService: ModalService,
  ) {}

  async toggleCompleted(task: Task): Promise<void> {
    if (!this.isLoading()) {
      if (task.completedAt) {
        await this.taskService.unCompleteTask(task, true);
      } else if (!task.metadata?.workflowBypass?.firstErrorAt) {
        await this.taskService.completeTask(task, true);
      }
    }
  }

  async completeTask(task: Task, bypass = false): Promise<void> {
    if (!this.isLoading()) {
      this.isLoading.set(true);
      await this.taskService.completeTask(task, true, bypass);
      this.isLoading.set(false);
    }
  }

  async onDeleteFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment, true);
  }

  async openMailPreviewModal(rootId: number): Promise<void> {
    this.modalService.open(EmailPreviewModalComponent, {
      data: rootId,
    });
  }

  setTask(task: Task): void {
    this.task.set(task);
  }

  setTaskTemplate(taskTemplate: TaskTemplate): void {
    this.taskTemplate.set(taskTemplate);
  }

  canDisplayEmailPreview(task: Task | null, taskTemplate: TaskTemplate | null): boolean {
    if (!task || !taskTemplate) {
      return false;
    }
    const subTasks: TaskTemplate[] | undefined = taskTemplate.tasks || Object.values(taskTemplate.taskLibrary!);
    const subTaskTemplate: TaskTemplate | undefined = subTasks?.find(
      (subTaskTemplate) => subTaskTemplate.code === task.code,
    );
    return (subTaskTemplate?.canPreviewMail && task.isActive) || false;
  }

  getButtonText(task: Task, taskTemplate: TaskTemplate): string {
    if (!task || !taskTemplate) {
      return '';
    }

    const subTasks: TaskTemplate[] | undefined = taskTemplate.tasks || Object.values(taskTemplate.taskLibrary!);
    return subTasks?.find((subTaskTemplate) => subTaskTemplate.code === task.code)?.config?.bypassableText ?? '';
  }

  openAnsweredConfiguratorModal(task: Task) {
    return this.taskService.openConfiguredTask(task);
  }
}
