import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { FormSettingsService } from '@dougs/core/form';
import { mergeObjects } from '@dougs/core/utils';

const COMPANY_REFERRER_SCHEMA: (string | Record<string, any>)[] = [
  {
    metadata: [
      'partnershipAccountingTrialPeriodDays',
      'partnershipAccountingFreeMonths',
      'partnershipAlreadyPaidMonthCount',
      'partnershipAccountingHasRemainder',
      'partnershipDiscountOnCompanyCreation',
      'partnershipSubscriptionPaymentMethod',
    ],
  },
];

@Injectable()
export class FormReferrerService extends FormSettingsService {
  private company!: Company;

  constructor(private readonly companyStateService: CompanyStateService) {
    super(COMPANY_REFERRER_SCHEMA);
  }

  get metadataFormGroup(): FormGroup {
    return this.formGroup.get('metadata') as FormGroup;
  }

  public populateForm(company: Company): void {
    this.populateFieldsForm(company);

    this.company = company;
  }

  async resetForm(): Promise<void> {
    this.formGroup.markAsPristine();
    await this.companyStateService.refreshCompany();
  }

  async onSubmit(): Promise<void> {
    const isFormValid: boolean = this.validateForm();
    if (isFormValid) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
      await this.updateCompany(this.formGroup.getRawValue());
    }
  }

  private async updateCompany(values: Partial<Company>): Promise<void> {
    const updatedCompany: Company = mergeObjects(this.company, values);
    const isUpdated: Company | null = await this.companyStateService.updateCompany(updatedCompany);

    if (!isUpdated) {
      await this.companyStateService.refreshCompany();
    }
  }
}
