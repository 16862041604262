<div class="subscription-line">
  <div class="subscription-line__left">
    <p class="small color-primary-700">Carte de paiement</p>
    @if (company.subscription?.cardBrand) {
      <div>
        <p class="small color-primary-700">****-****-****-{{ company.subscription?.cardLast4 }}</p>
        <p class="small">
          (expire
          {{ company.subscription.cardExpirationDate | date: 'MM-yyyy' }})
        </p>
      </div>
    }
  </div>
  <div class="subscription-line__right">
    <dougs-button
      [fullWidth]="true"
      size="small"
      color="secondary"
      (click)="bankCardNewComponentService.updateCard(company)"
    >
      {{ company.subscription?.cardBrand ? 'Mettre à jour' : 'Ajouter une carte' }}
    </dougs-button>
    @if (
      company.subscription?.cardBrand &&
      (userStateService.loggedInUser$ | async)?.flags?.includes(USER_FLAG.CAN_DELETE_PAYMENT_CARD)
    ) {
      <dougs-button
        size="small"
        [fullWidth]="true"
        color="delete"
        (click)="bankCardNewComponentService.deleteCard(company)"
      >
        Supprimer
      </dougs-button>
    }
    @if (bankCardNewComponentService.showStripeDeleteButton$ | async) {
      <dougs-button
        size="small"
        [fullWidth]="true"
        color="delete"
        (click)="bankCardNewComponentService.deleteCustomerOnStripe(company)"
      >
        Supprimer sur Stripe
      </dougs-button>
    }
  </div>
</div>
