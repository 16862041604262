import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ModalService } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';

@Injectable({ providedIn: 'root' })
export class SubscriptionNewComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
  ) {}

  async openPlansModal(upsellCampaign?: string): Promise<void> {
    const { ActivateComptastartModalComponent } = await import(
      '../../../modals/activate-comptastart/activate-comptastart-modal.component'
    );
    const { PlansModalComponent } = await import('../../../modals/plans-modal/plans-modal.component');
    const plansModalComponent: typeof ActivateComptastartModalComponent | typeof PlansModalComponent = this
      .userStateService.activeUser.isEligibleToComptastart
      ? ActivateComptastartModalComponent
      : PlansModalComponent;

    this.metricsService.pushMixpanelEvent('Accounting Display Plans Clicked', {
      'Settings Completion Percent': this.companyStateService.activeCompany?.completion?.percent,
    });

    await lastValueFrom(
      this.modalService.open<boolean | null>(plansModalComponent, {
        data: {
          upsellCampaign,
        },
      }).afterClosed$,
    );
  }
}
