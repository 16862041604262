import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerDownloadMobileAppService extends TaskActionService {
  protected _isAvailable = true;

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    const { DownloadMobileAppModalComponent } = await import(
      '../../modals/download-mobile-app-modal/download-mobile-app-modal.component'
    );
    if ((await lastValueFrom(this.modalService.open(DownloadMobileAppModalComponent).afterClosed$)).data) {
      await this.taskService.completeTask(task);
    }
  }
}
