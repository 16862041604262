import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import {
  CustomerSurvey,
  CustomerSurveyStatusEnum,
  CustomerSurveyStep,
  CustomerSurveyStepTypeEnum,
  Task,
} from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class SurveyAppointmentModalComponentService {
  survey: CustomerSurvey | null = null;
  currentStep: CustomerSurveyStep | null = null;
  shouldShowFooter = false;
  isLastStep = false;
  formGroup: FormGroup = new FormGroup({});

  private currentStepIndex = -1;

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly modalRef: ModalRef,
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
  ) {
    this.setSurvey();
    this.updateCurrentStep();
  }

  saveAndNextStep(): void {
    if (this.currentStep?.fieldName) {
      this.saveSurveyField(this.currentStep.fieldName, this.formGroup.get(this.currentStep.fieldName)?.value);
    }
    this.updateCurrentStep();
  }

  async quitSurvey(): Promise<void> {
    let status: CustomerSurveyStatusEnum;
    if (!Object.keys(this.task.formData || {}).length) {
      status = CustomerSurveyStatusEnum.dismissed;
    } else {
      status = CustomerSurveyStatusEnum.completed;
    }
    this.saveSurveyField('completionStatus', status);
    await this.controlPanelTasksStateService.updateTask(this.task);

    this.closeModal();
  }

  private saveSurveyField(fieldName: string, value: any): void {
    this.task.formData = { ...this.task.formData, [fieldName]: value };
  }

  private setSurvey(): void {
    const { survey } = this.task.metadata;
    if (!survey) {
      return;
    }

    this.survey = survey;
  }

  private updateCurrentStep(): void {
    if (!this.survey) {
      return;
    }

    const nextStepIndex: number = this.currentStepIndex + 1;
    if (nextStepIndex === this.survey.steps.length) {
      return;
    }

    this.currentStepIndex = nextStepIndex;
    this.currentStep = this.survey.steps[this.currentStepIndex];

    if (this.currentStepIndex === this.survey.steps.length - 1) {
      this.setLastStep();
    }

    this.currentStepChanged();
  }

  private setLastStep(): void {
    this.isLastStep = true;
  }

  private currentStepChanged(): void {
    this.updateShouldShowFooter();
    if (this.currentStep?.fieldName) {
      this.formGroup.addControl(this.currentStep.fieldName, new FormControl(''));
    }
  }

  private updateShouldShowFooter(): void {
    this.shouldShowFooter = this.isLastStep || this.currentStep?.type !== CustomerSurveyStepTypeEnum.score;
  }

  private closeModal(): void {
    this.modalRef.close();
  }
}
